import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client/core";

const apolloClient = new ApolloClient({
    link: createHttpLink({
        uri: route("graphql"),
    }),
    cache: new InMemoryCache(),
});

export default apolloClient;
