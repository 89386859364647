import "./bootstrap";
import "../css/app.css";

import { createApp, h, provide, ref } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { i18nVue } from "laravel-vue-i18n";
import mitt from "mitt";
import VueTheMask from "vue-the-mask";
import { ZiggyVue } from "ziggy-js/dist/vue.m";
import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/apolloClient";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/vue";
import { Ziggy } from "./ziggy";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText ||
    "Van Rijn Coating";
const emitter = mitt();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({
            setup: () => {
                const serialPort = ref(null);
                const serialPortReader = ref(null);

                provide("serial_port", serialPort);
                provide("serial_port_reader", serialPortReader);
                provide("emitter", emitter);
                provide(DefaultApolloClient, apolloClient);
            },
            render: () => h(App, props),
        })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(i18nVue, {
                resolve: async (lang) => {
                    const langs = import.meta.glob("../../lang/*.json");
                    if (lang) {
                        return langs[`../../lang/${lang}.json`]();
                    }

                    return langs[`../../lang/nl.json`]();
                },
            })
            .use(VueTheMask)
            .mount(el);

        Sentry.init({
            app,
            dsn: "",
            environment: "production",
            tracesSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
            integrations: [new BrowserTracing()],
        });

        return app;
    },
});
